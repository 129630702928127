import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import {showNotification, clearAll} from '@elements/alert-notification';
import formDataEntries from 'form-data-entries';
import {
    findIn,
    findAll,
    on,
    find,
    removeAttribute,
    setAttribute,
    addClass,
    removeClass,
    findAllIn,
    trigger,
    closest, hasClass
} from '@elements/dom-utils';
import {onFind, initInScope, cleanUpInScope} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";
const configName = '_cartConfig';
import axios from "axios";
import * as tracking from "@elements/tracking";
import Tooltip from 'bootstrap/js/dist/tooltip';
import {translate} from '@elements/translations';
import * as formValidation from '@elements/form-validation';

let count,
    namePlaceholder,
    totalPricePlaceholder,
    vatPlaceholder;

const defaultSelectors = {
    base: '.js-cart',
    form: '.js-cart__form',
    quickRecord: '.js-cart__quick-record',
    quickRecordSubmit: '.js-cart__quick-record-submit',
    addButton: '.js-cart__add',
    removeButton: '.js-cart__remove',
    loading: '.js-cart__loading',
    result: '.js-cart__result',
    link: '.js-cart__link',
    count: '.js-cart__count',
    name: '.js-cart__name',
    total: '.js-cart__total',
    vat: '.js-cart__vat',
    submit: '.js-cart__submit'
};

const defaultOptions = {
    formTrigger: 'submit',
    emptyCart: false,
    onAdd: () => {},
    onAddSucceed: () => {},
    onAddFailed: () => {}
};


export function init(options = defaultOptions, selectors = defaultSelectors){
    count = findAll(selectors.count);
    namePlaceholder = findAll(selectors.name);
    totalPricePlaceholder = findAll(selectors.total);
    vatPlaceholder = findAll(selectors.vat);

    onFind(selectors.form, function (form) {
        let container = closest('.js-cart', form) ? closest('.js-cart', form) : form,
            isModal = form.getAttribute('data-cart-modal');

        if (isModal) {
            container = form;
        }

        options = {
            ...defaultOptions,
            ...options,
            ...getPrefixedDataSet('cart', form)
        };

        let formTrigger = form.getAttribute('data-cart-form-trigger') ? form.getAttribute('data-cart-form-trigger') : 'submit';

        requireConfigValues(['updateUrl'], configName);

        const handleForm = (evt) => {
            evt.preventDefault();

            let formDataEntries = getFormDataEntries([form]),
                params = new URLSearchParams(formDataEntries),
                url = form.getAttribute('data-action') || getConfigValue('updateUrl', configName);

            updateCart(url, params, container, options);

            call(options.onAdd);
        }

        if (form && form.length) {
            form.addEventListener(formTrigger, handleForm);
        }

        on('change', (evt) => {
            handleSubmitButtonState(form);
        }, form);

        return {
            cleanUp: () => {
                form.removeEventListener(formTrigger, handleForm);
            }
        };
    });

    onFind(selectors.quickRecord, function (form) {
        let container = closest('.js-cart', form) ? closest('.js-cart', form) : form;

        options = {
            ...defaultOptions,
            ...options,
            ...getPrefixedDataSet('cart', form)
        };

        const handleForm = (evt) => {
            evt.preventDefault();

            let params = new FormData(form),
                url = form.getAttribute('data-action');

            quickRecordCart(url, params, container, form, options);
        }

        on('change', (evt) => {
            formValidation.getApi(form).then((formApi) => {
                let formInstance = formApi.getFormInstance();

                formInstance.validate().then((state) => {
                    if (state === 'Valid') {
                        removeAttribute('disabled', findIn(selectors.quickRecordSubmit, form));
                    } else {
                        setAttribute('disabled', true, findIn(selectors.quickRecordSubmit, form));
                    }
                });
            });
        }, form);

        if (findIn(selectors.quickRecordSubmit, form)) {
            findIn(selectors.quickRecordSubmit, form).addEventListener('click', handleForm);
        }

        on('submit', (evt) => {
            evt.preventDefault();
        }, form);

        return {
            cleanUp: () => {
                findIn(selectors.quickRecordSubmit, form).removeEventListener('click', handleForm);
            }
        };
    });

    onFind(selectors.link, function (link) {
        options = {
            ...defaultOptions,
            ...options,
            ...getPrefixedDataSet('cart', link)
        };

        const handleClick = (evt) => {
            evt.preventDefault();

            updateCart(link.getAttribute('href'), {}, closest('.js-cart', link), options);

            call(options.onAdd);
        }

        if (link) {
            link.addEventListener('click', handleClick);
        }

        return {
            cleanUp: () => {
                link.removeEventListener('click', handleClick);
            }
        };
    });

    onFind(selectors.base, function (baseElement) {
        createCart(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

    onFind(selectors.removeButton, function (removeButton) {
        on('click', function () {
            let params = new URLSearchParams({removeId: removeButton.getAttribute('data-product-id'), cartItemId: removeButton.getAttribute('data-cartitem-id')});

            updateCart(getConfigValue('updateUrl', configName), params, find(selectors.base), options);
        }, removeButton);
    });

    onFind(selectors.submit, function (submitButton) {
        on('click', (evt) => {
            if (hasClass('is-disabled', submitButton)) {
                evt.preventDefault();
            }
        }, submitButton);
    });
    getCartInfo();
}

export function createCart(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('cart', baseElement)
    };

    requireConfigValues(['infoUrl'], configName);

    findAllIn('.js-cart__item', baseElement).map((item) => {
        let productId = item.getAttribute('data-product-id'),
            cartItemId = item.getAttribute('data-cartitem-id');

        on('change', function (evt) {
            let params = new FormData();

            params.append('cartItemId', cartItemId)
            params.append('productId', productId)
            findAllIn('input, select', item).map((input) => params.append(input.name, input.value))

            params = new URLSearchParams(params);

            updateCart(getConfigValue('updateUrl', configName), params, baseElement, options);

        }, item);


        let removeButton = findIn(selectors.removeButton, item);
        if (removeButton) {
            on('click', function () {
                let params = new URLSearchParams({removeId: productId, cartItemId: cartItemId});

                updateCart(getConfigValue('updateUrl', configName), params, baseElement, options);
            }, removeButton);
        }
    });

    handleSubmitButtonState(baseElement);
}

export function updateCart(url, params, baseElement, options = defaultOptions) {
    let listContent = findIn('.js-cart__result', baseElement),
        loadingElement = findIn('.js-cart__loading', baseElement) !== null ? findIn('.js-cart__loading', baseElement) : listContent ? findIn('.js-cart__loading', listContent) : findIn('.js-cart__loading', closest('.js-cart', baseElement));

    if (loadingElement) {
        removeAttribute('hidden', loadingElement);
    }

    addClass('is-loading', baseElement);

    let pendingRequest = axios({method: 'POST', url: url, data: params });

    pendingRequest.then((response) => {
        let result = response.data;

        if (result.success) {

            //set list content
            if (result.content && listContent) {
                listContent.innerHTML = result.content;

                if (result.count === 0) {
                    findAll('.js-cart__submit').map((button) => addClass('is-disabled', button));
                } else {
                    findAll('.js-cart__submit').map((button) => removeClass('is-disabled', button));
                }

                // cleanUpInScope();
                cleanUpInScope(baseElement.parentElement);
                initInScope(baseElement.parentElement);
            }

            //set count
            if (typeof result.count !== "undefined") {
                setCount(count, result.count);
            }

            //set name
            if (typeof result.name !== "undefined") {
                setName(namePlaceholder, result.name);
            }

            //set total price
            if (typeof result.totalPrice !== "undefined") {
                setTotal(totalPricePlaceholder, result.totalPrice);
            }

            //set total price
            if (typeof result.vat !== "undefined") {
                setInnerHtml(vatPlaceholder, result.vat);
            }

            // set loading state
            addClass('is-finished', baseElement);

            //trigger affix recalculation
            trigger('cart.success', baseElement);

            let t = setTimeout(() => {
                removeClass('is-finished', baseElement);

                clearTimeout(t);
            }, 3000);

            handleSubmitButtonState(baseElement);

            call(options.onAddSucceed);
        }else{
            call(options.onAddFailed);
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    }).then(() => {
        // always executed
        if (loadingElement) {
            setAttribute('hidden', true, loadingElement);
        }

        removeClass('is-loading', baseElement);
    });

    clearAll();
    showNotification(pendingRequest.then((x) => x.data));

    tracking.responseTracking(pendingRequest);
}

export function quickRecordCart(url, params, baseElement, form, options = defaultOptions) {
    let loadingElement = findIn('.js-cart__quick-record-loading', form);

    if (loadingElement) {
        removeAttribute('hidden', loadingElement);
    }

    let pendingRequest = axios({method: 'POST', headers: { "Content-Type": "multipart/form-data" }, url: url, data: params });

    pendingRequest.then((response) => {
        let result = response.data;

        if(result.success) {
            if (result.content) {
                let listContent = findIn('.js-cart__result', baseElement);
                listContent.innerHTML = result.content;

                cleanUpInScope(baseElement.parentElement);
                initInScope(baseElement.parentElement);
            }

            if  (result.newCart) {
                window.location.reload();
            }

            // if (errors && errorContainer) {
            //     removeAttribute('hidden', errorContainer);
            //     errorContainer.innerHTML = `<strong>${translate('cart.import-error')}</strong><ul>${ errors.map((error) => `<li>${error}</li>`).join('') }</ul>`;
            // } else {
            //     updateCart(getConfigValue('updateUrl', configName), {}, baseElement, options);

                window.scrollTo({
                    top: baseElement.offsetTop - 100,
                    behavior: 'smooth'
                });
            // }

            if (loadingElement) {
                setAttribute('hidden', true, loadingElement);
            }
        }

    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    }).then(() => {
        // always executed
        if (loadingElement) {
            setAttribute('hidden', true, loadingElement);
        }
    });

    clearAll();
    showNotification(pendingRequest.then((x) => x.data));

    tracking.responseTracking(pendingRequest);
}

export function getCartInfo() {
    requireConfigValues(['infoUrl'], configName);

    let request = axios({method: 'GET', url: getConfigValue('infoUrl', configName)});

    request.then((response) => {
        let result = response.data;
        if (result.success) {
            if (typeof result.count !== "undefined") {
                setCount(count, result.count);
            }

            if (typeof result.name !== "undefined") {
                setName(namePlaceholder, result.name);
            }
        }
    }).catch(e => {console.warn(e);});
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}

function setCount(elements, count) {
    elements.map((item) => {
        if (item.getAttribute('data-count-visible')) {
            removeAttribute('hidden', item);
            addClass('is-active', item);
            item.textContent = count;
        } else {
            if (count) {
                removeAttribute('hidden', item);
                addClass('is-active', item);
                item.textContent = count;
            } else {
                elements.map((item) => {
                    removeClass('is-active', item);
                    setAttribute('hidden', true, item);
                });
            }
        }
    });
}

function setName(elements, name) {
    if (name) {
        elements.map((item) => {
            item.textContent = name;
        });
    }
}

function setTotal(elements, name) {
    if (name) {
        elements.map((item) => {
            item.innerHTML = name;
        });
    }
}

function setInnerHtml(elements, innerHtml) {
    if (innerHtml) {
        elements.map((item) => {
            item.innerHTML = innerHtml;
        });
    }
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}

function handleSubmitButtonState(form) {
    if (findAll('.js-cart__submit')) {
        findAll('.js-cart__submit').map((button) => {
            const tooltip = Tooltip.getOrCreateInstance(button, {
                title: translate('checkout.cart-disabled-delivery'),
                customClass: 'tooltip--lg'
            });

            if (findAllIn('.js-cart__form-delivery', form).length === 0 || findIn('.js-cart__form-delivery:checked', form)) {
                removeAttribute('disabled', button);
                removeClass('is-disabled', button);
                tooltip.disable();
            } else {
                setAttribute('disabled', true, button);
                addClass('is-disabled', button);
                tooltip.enable();
            }
        });
    }
}