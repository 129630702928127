/** @jsx h */

import {h, render} from "preact";
import {components}  from 'react-select'
import AsyncSelect  from 'react-select/async'
import {onFind} from "@elements/init-modules-in-scope";
import {closest, removeClass} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import throwError from "@elements/throw-error";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import axios from "axios";

const defaultSelectors = {
    base: '.js-typeahead'
};

const defaultOptions = {
    isClearable: true,
    defaultValue: "",
    name: 'q',
    defaultMenuIsOpen: false,
    minLength: 2
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let options = {
            ...defaultOptions,
            ...options,
            ...transformDataSetOptions(getPrefixedDataSet('typeahead', baseElement))
        };

        render(<TypeaheadConfig isClearable={options.isClearable}
                                placeholder={options.placeholder}
                                value={options.defaultValue}
                                name={options.name}
                                searchName={options.searchName}
                                minLength={options.minLength}
                                groups={options.groups}
                                action={options.action}
                                defaultOptions={options.options}
                                menuIsOpen={!!(options.defaultMenuIsOpen && options.defaultValue)}
                                urlName={options.url}/>,
            baseElement);
    });
}

function TypeaheadConfig({
                             defaultOptions = [],
                             value = "",
                             placeholder = "",
                             name,
                             searchName = 'q',
                             minLength = 0,
                             groups = [],
                             action = null,
                             urlName = "",
                             menuIsOpen
                         }) {

    const [inputValue, setInputValue] = useState({input: value});
    const [isOpen, setIsOpen] = useState(menuIsOpen);

    const loadData = (stringValue, callback) => {
        let data = {
            [name]: stringValue
        };

        const url = addParamsToUrl(urlName, data);

        if (groups.length) {
            groups = JSON.parse(groups);
        }

        axios({
            method: 'GET',
            url: url
        }).then(function (result) {
                const options = [];
                options.push({label: '', isPlaceholder: true});

                if (groups.length) {
                    for (let i = 0; i < groups.length; i++) {
                        if (result.data[groups[i].name]) {
                            let newGroup = {
                                label: groups[i].title,
                                overviewSearchName: groups[i].overviewSearchName ? groups[i].overviewSearchName : name,
                                overviewUrl: groups[i].overviewUrl,
                                overviewText: groups[i].overviewText,
                                overviewIcon: groups[i].overviewIcon,
                                options: result.data[groups[i].name]
                            };

                            options.push(newGroup);
                        }
                    }
                } else {
                    result.data.map((item, index) => {
                        item.label = item.text;

                        options.push(item);
                    });
                }

                callback(options);
            })
            .catch(function (error) {
                console.error(`Could not load configuration data for typeahead`, error);
            });
    };

    const handleInputChange = (newValue) => {
        let input = newValue;

        setInputValue({ input });

        if(input.length >= minLength) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }

        return input;
    };

    const handleChange = (currentSelection) => {
        if (currentSelection) {
            if (currentSelection.isPlaceholder) {
                handleSubmit();
            } else {
                if (window['dataLayer']) {
                    dataLayer.push({
                        'event': 'genericGtmEvent',
                        'genericEventCategory': 'search',
                        'genericEventAction': 'autocomplete-click',
                        'genericEventLabel': currentSelection.text
                    });
                }

                window.location = currentSelection.url;
            }
        }
    };

    const handleSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
        }

        if (window['dataLayer']) {
            dataLayer.push({
                'event': 'genericGtmEvent',
                'genericEventCategory': 'search',
                'genericEventAction': 'autocomplete-click',
                'genericEventLabel': inputValue.input
            });
        }

        window.location.href = addParamsToUrl(action, {[searchName ? searchName : name]: encodeURIComponent(inputValue.input)});
    };

    const styling = {
        control: styles => ({ ...styles, backgroundColor: 'transparent', borderWidth: '0' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#fff' : 'transparent',
            }
        },
        menu: styles => ({ ...styles, backgroundColor: '#fff'})
    };

    const [lastValue, setLastValue] = useState(value);

    return (
        <form className={"typeahead__form"} action={action} onSubmit={(evt) => handleSubmit(evt)}>
            <AsyncSelect
                inputValue={inputValue.input}
                name={name}
                searchName={searchName}
                action={action}
                className="react-select-wrapper"
                classNamePrefix="react-select"
                cacheOptions
                menuIsOpen={isOpen}
                loadOptions={isOpen ? loadData : ''}
                defaultOptions
                loadingMessage={() => translate('search-loading')}
                placeholder={placeholder}
                styles={styling}
                onInputChange={handleInputChange}
                onChange={handleChange}
                onInput={setLastValue(inputValue.input)}
                components={{ Group, GroupHeading, Option, DropdownIndicator, SingleValue }}
                valueBeforeClose={lastValue}
                onMenuClose={() => {
                    setLastValue(inputValue.input)
                    setInputValue({input: lastValue})
                }}
                onResetClick={() => {
                    setLastValue('')
                    setInputValue({input: ''})
                }}
            />
        </form>
    )

}

const DropdownIndicator = props => {
    const handleClick = () => {
        let param = props.selectProps.name ? props.selectProps.searchName : props.selectProps.name,
            value = props.selectProps.inputValue ? props.selectProps.inputValue : props.selectProps.valueBeforeClose;

        if (window['dataLayer']) {
            dataLayer.push({
                'event': 'genericGtmEvent',
                'genericEventCategory': 'search',
                'genericEventAction': 'autocomplete-click',
                'genericEventLabel': value
            });
        }

        window.location.href = addParamsToUrl(props.selectProps.action, {[param]: encodeURIComponent(value)});
    };

    const handleResetClick = (evt) => {
        props.selectProps.onResetClick();

        removeClass('has-value', closest('.typeahead', evt.target));
    };

    return (
        <components.DropdownIndicator {...props}>
            <button type={"button"} onTouchStart={(evt) => handleResetClick(evt)} onClick={(evt) => handleResetClick(evt)} className={"typeahead__reset btn btn-link asdf"}><span className={"icon icon-close"}></span></button>
            <span className="typeahead__divider"></span>
            <button type={"button"} onClick={() => handleClick()} onTouchStart={() => handleClick()} className={"typeahead__submit btn btn-link"}>{translate('search-submit')}</button>
        </components.DropdownIndicator>
    );
};

const Group = props => {
    return (
        <components.Group {...props}>
            {props.children}
        </components.Group>
    );
};


const GroupHeading = props => {
    let showLink = !!props.data.overviewUrl;
    let url = addParamsToUrl(props.data.overviewUrl, {[props.data.overviewSearchName]: encodeURIComponent(props.selectProps.inputValue)})

    const handleClick = () => {
        if (window['dataLayer']) {
            dataLayer.push({
                'event': 'genericGtmEvent',
                'genericEventCategory': 'search',
                'genericEventAction': 'autocomplete-click',
                'genericEventLabel': props.data.overviewText
            });
        }
    };

    return (
        <components.GroupHeading {...props}>
            <div className="row">
                <div className="col">
                    <span className={'typeahead__group-icon icon ' + props.data.overviewIcon}></span> {props.children}
                </div>
                {showLink ? (
                    <div className="col-xl-7 text-xl-end">
                        <a className="react-select__group-link typeahead__more" href={url} onClick={() => handleClick()}>
                            <span className="icon icon-arrow typeahead__more__icon"></span>
                            {props.data.overviewText}
                        </a>
                    </div>
                ) : null}
            </div>
        </components.GroupHeading>
    );
};

const Option = props => {
    let isPlaceholder = props.data.isPlaceholder;

    return (
        <components.Option {...props}>
            {!isPlaceholder ? (
                <div className="stretch-link">
                    <div>
                        <a href={props.data.url} className="stretch-link__link" dangerouslySetInnerHTML={{ __html: highlight(props.data.text, props.selectProps.inputValue) }}></a>
                    </div>
                </div>
            ) : null }
        </components.Option>
    );
};

const SingleValue = props => {
    return (
        <components.SingleValue {...props}>
            {props.children.replace( /(<([^>]+)>)/ig, '')}
        </components.SingleValue>
    );
};


function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.options) {
        try {
            transformedOptions.options = JSON.parse(transformedOptions.options)
        } catch (e) {
            transformedOptions.options = null;
            throwError(OPTIONS_ERROR_MESSAGE);
        }
    }

    return transformedOptions;
}

export const addParamsToUrl = (url, params) =>
    url + (url.indexOf('?') >= 0 ? '&': '?')
    + Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

function highlight(content, search) {
    return content.replace(new RegExp(search, "gi"), (match) => `<span class="font-bold">${match}</span>`);
}


const OPTIONS_ERROR_MESSAGE = `Typeahead error: data-typeahead-options has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;