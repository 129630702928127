import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn} from "@elements/dom-utils";

export function init () {
    onFind('.js-hotspot__toggle', function (hotspotButton) {
        on('click', function () {
            let container = closest('.js-hotspot-container', hotspotButton);
            let hotspot = closest('.js-hotspot', hotspotButton);
            let detail = findIn('.js-hotspot__detail', hotspot);

            if(!hasClass('is-open', hotspot)) {
                addClass('is-open', hotspot);

                window.addEventListener("click", function _listener(evt) {
                    if (!hotspot.contains(evt.target)) {
                        removeClass('is-open', hotspot);

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);


                //set detail position
                removeClass('hotspot--detail-top', hotspot);
                removeClass('hotspot--detail-left', hotspot);

                let offset = 5,
                    detailHeight = detail.getBoundingClientRect().height,
                    posTop = hotspot.getBoundingClientRect().top - container.getBoundingClientRect().top;

                if(posTop + detailHeight > container.getBoundingClientRect().height) {
                    addClass('hotspot--detail-top', hotspot);

                    if (matchMedia('(max-width: 767px)').matches) {
                        detail.style.top = posTop - detailHeight - offset + 'px'
                    }
                } else {
                    if (matchMedia('(max-width: 767px)').matches) {
                        detail.style.top = posTop + hotspot.getBoundingClientRect().height + offset + 'px'
                    }
                }

                //if overflow right
                if(detail.getBoundingClientRect().left + detail.getBoundingClientRect().width > container.getBoundingClientRect().width + container.getBoundingClientRect().left) {
                    addClass('hotspot--detail-left', hotspot);
                }
            } else {
                removeClass('is-open', hotspot);
            }

        }, hotspotButton);
    });
}