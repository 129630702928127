"use strict";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    if(isMobile()){
        onFind('.js-collapse-xs',function (element) {
            element.setAttribute('data-bs-toggle', 'collapse');
        });
    }
}