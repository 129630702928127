"use strict";
import {on, find} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-ecommerce-tracking', function (button) {
        on('click', function (evt) {
            if (window.cookieconsent && window.cookieconsent.currentConsentStatus().statistic === true) {
                let keys = JSON.parse(button.getAttribute('data-tracking-keys'));

                keys.map((key) => {
                    if (button.getAttribute('data-tracking-id')) {
                        let trackingData = JSON.parse(_config[key][button.getAttribute('data-tracking-id')]);
                        if (trackingData) {
                            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                            dataLayer.push(trackingData);
                        }
                    } else {
                        let trackingData = JSON.parse(_config[key]);

                        if (trackingData) {
                            if (trackingData.event === 'add_payment_info') {
                                trackingData.ecommerce = {...trackingData.ecommerce, 'payment_type': find('.js-payment__input:checked').value};
                            } else if (trackingData.event === 'add_shipping_info') {
                                trackingData.ecommerce = {...trackingData.ecommerce, 'shipping_tier': trackingData.shippingType};
                            }

                            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                            dataLayer.push(trackingData);
                        }
                    }
                });
            }
        }, button);
    });

    onFind('.js-tracking-url', function (link) {
        on('click', (evt) => {
            if (window.cookieconsent && window.cookieconsent.currentConsentStatus().statistic === true) {
                evt.preventDefault();

                let url = link.getAttribute('href'),
                    target = link.getAttribute('target') ? link.getAttribute('target') : '_self';

                let ga = window[window['GoogleAnalyticsObject']];
                if (ga && typeof ga.getAll === 'function') {
                    url = (new window.gaplugins.Linker(ga.getAll()[0])).decorate(url);
                }

                window.open(url, target);
            }
        }, link);
    });

    onFind('.js-cart-quickrecord-tracking', function (button) {
        on('click', function (evt) {
            if (window.cookieconsent && window.cookieconsent.currentConsentStatus().statistic === true) {

                let method = button.getAttribute('data-cart-quickrecord-method');
                let trackingData = {
                    'event': 'cart_quick_record',
                    'cart_import_method': method,
                };

                if (method === 'clipboard') {
                    trackingData = {...trackingData,
                        'delimiter': find('.js-cart__quick-record-clipboard-delimiter').value,
                        'first_row_headline': find('.js-cart__quick-record-clipboard-first-row-headline').checked,
                        'create_new_cart': find('.js-cart__quick-record-clipboard-create-new-cart').checked
                    }
                } else if (method === 'file_upload') {
                    trackingData = {...trackingData,
                        'blum_configurator_file': find('.js-cart__quick-record-file-blum-configurator').checked,
                        'first_row_headline': find('.js-cart__quick-record-file-first-row-headline').checked,
                        'create_new_cart': find('.js-cart__quick-record-file-create-new-cart').checked
                    }
                }

                dataLayer.push(trackingData);
            }
        }, button);
    });
}