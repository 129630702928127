import axios from "axios";

export function init () {
    if (_config.loggedIn) {
        setInterval(function () {
            axios({
                method: 'GET',
                url: '/login/ping',
            });
        }, 1E6);
    }
}