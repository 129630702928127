import {onFind} from "@elements/init-modules-in-scope";
import {findIn, closest, on, addClass, findAllIn, removeClass, removeAttribute, setAttribute} from '@elements/dom-utils';
import axios from "axios";
import Modal from "bootstrap/js/dist/modal";
import * as formValidation from '@elements/form-validation';
import * as tracking from "@elements/tracking";

export function init () {
    onFind('.js-callback', (container) => {
        let content = findIn('.js-callback__content', container);
        let button = findIn('.js-callback__button', container);
        let close = findIn('.js-callback__close', container);
        let form = findIn('.js-callback__form', container);

        if(button){
            on('click', (evt) => {
                addClass('is-active', button);
                addClass('is-active', content);
            }, button);

            on('click', (evt) => {
                removeClass('is-active', button);
                removeClass('is-active', content);
            }, close);
        }
        
        on('form-validation.submit', (evt) => {
            let loading = findIn('.js-callback__loading', form);

            removeAttribute('hidden', loading);

            let pendingRequest = axios({
                method: 'POST',
                url: form.getAttribute('data-action'),
                data: new FormData(form)
            });

            pendingRequest.then((response) => {
               if(response.data.success){
                   findAllIn('input:not([type=hidden])', form).map(input => {
                       if(input.getAttribute('type') === 'checkbox'){
                           input.checked = false;
                       }else{
                           input.value = '';
                       }
                   });

                   if(closest('.modal', form)){
                       let myModal = Modal.getOrCreateInstance(closest('.modal', form));
                       myModal.hide();
                   }else{
                       removeClass('is-active', button);
                       removeClass('is-active', content);
                   }

                   formValidation.getApi(form).then((formApi) => {
                       let formInstance = formApi.getFormInstance();
                       formInstance.resetForm(true);
                   });

                   window.open(response.data.redirectUrl, '_blank').focus();
               }else{
                    let errorDiv = document.createElement('div');
                    errorDiv.appendChild(document.createTextNode(form.getAttribute('data-error')));
                    addClass('form-errors', errorDiv);
                    addClass('invalid-feedback', errorDiv);
                    form.appendChild(errorDiv);
               }
            }).catch(error => {
                console.log(error);
            }).then(x => {
                setAttribute('hidden', true, loading);
            });

            tracking.responseTracking(pendingRequest);
        }, form);

    });
}