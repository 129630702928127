"use strict";
import {on, findAllIn, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";
import * as formValidation from '@elements/form-validation';
import * as tracking from "@elements/tracking";

export function init() {
    onFind('.js-form', function (form) {
        on('submit', (evt) => {
            evt.preventDefault();
        }, form);

        on('form-validation.submit', (evt) => {
            evt.preventDefault();

            let pendingRequest,
                url = form.getAttribute('data-action'),
                method = form.getAttribute('method'),
                headers = {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                data = new FormData(form);

            if (method === 'GET') {
                pendingRequest = axios({method: 'GET', url: url, params: data, headers: headers});
            } else {
                pendingRequest = axios({method: 'POST', url: url, data: data, headers: headers });
            }

            asyncAppend({
                target: findAllIn('.js-form__content', form),
                loading: findAllIn('.js-form__loading', form)
            }, pendingRequest.then((x) => {
                let t = setTimeout(() => {
                    formValidation.getApi(form).then((formApi) => {
                        formApi.getFormInstance().destroy();
                    });
                    formValidation.init();


                    if (grecaptcha) {
                        grecaptcha.render(findIn('.g-recaptcha', form), {sitekey: findIn('.g-recaptcha', form).getAttribute('data-sitekey')})
                    }

                }, 1)

                return x.data;
            }));

            tracking.responseTracking(pendingRequest);
        }, form);
    });

    window.recaptchaCallback = function(){
        document.getElementById("recaptcha-blocker").value = "not-empty";
    }
}