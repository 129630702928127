import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-info-modal', function (modal) {
        let modalInstance = new Modal(modal);

        const getCookie = (cname) =>{
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let cookies = decodedCookie.split(';');
            for(let i = 0; i < cookies.length; i++) {
                let c = cookies[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        const setCookie = (name, value, expiredays) => {
            const date = new Date();
            date.setTime(date.getTime() + (expiredays*24*60*60*1000));
            let expires = "expires="+ date.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        }

        if (!getCookie(`info-modal-${modal.getAttribute('data-id')}`)) {
            modalInstance.show();
            setCookie(`info-modal-${modal.getAttribute('data-id')}`, true, 1);
        }
    });
}