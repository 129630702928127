import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, addClass, removeClass} from '@elements/dom-utils';

export function init () {
    onFind('.js-info-box', function (infobox) {
        let btn = findIn('.js-info-box-dismiss', infobox);

        let isHidden = sessionStorage.getItem('infoBoxHidden') ? JSON.parse(sessionStorage.getItem('infoBoxHidden')) : false;

        if (!isHidden) {
            addClass('is-open', infobox);

            on('click', () => {
                removeClass('is-open', infobox);
                sessionStorage.setItem('infoBoxHidden', true);
            }, btn)
        }
    });
}