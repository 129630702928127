import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';

import Toast from 'bootstrap/js/dist/toast';
import {init} from '@elements/alert-notification';
init({
    key: '__alertNotification',
    renderFunction: function renderFunction({type, title, content, closable = true, styleModifier = '', placement, defaultRender, autohide = true} = {}, options) {
        if (content || title) {
            let containers = options.container;

            containers.map(function(container) {
                container.removeAttribute('hidden');
                container.insertAdjacentHTML('beforeend', (
                    `<div class="toast toast--${type} ${styleModifier}" role="alert" data-bs-autohide="${autohide}" >
                        ${closable ? (
                        `<button type="button" 
                             class="toast__close" 
                             data-bs-dismiss="toast" 
                             aria-label="${options.closeText}"  
                             title="${options.closeText}">
                             <span class="icon icon-close"></span>
                         </button>`
                        ) : ''} 

                    ${title ? (
                        `<div class="toast-header h5 mb-0 d-flex">
                        ${title}               
                    </div>`
                    ) : ''}
                    
                ${content ? (
                        `<div class="toast-body">
                        ${content}               
                    </div>`
                    ) : ''}
            </div>`));

                new Toast(findIn('.toast', container)).show();
            });
        }
    }
});

import * as renderTemplate from './render-template';
renderTemplate.init();

// import * as contentVisibility from './content-visibility';
// contentVisibility.init();

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element, {
        toggle: false
    });
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});

import Tooltip from 'bootstrap/js/dist/tooltip';
onFind('[data-bs-toggle="tooltip"]',function (element) {
    new Tooltip(element);
});

import * as cart from './cart'
cart.init();

import * as CollapseXs from './collapse-xs';
CollapseXs.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as nav from './nav';
nav.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import * as hotspot from './hotspot';
hotspot.init();

import * as typeahead from './typeahead';
typeahead.init();

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({scrollOffset: matchMedia('(max-width: 767px)').matches ? 152 : 190, initialPosition: true});

// import * as smoothScrollExtend from './smoothscroll-extend';
// smoothScrollExtend.init();

import * as scrollTop from './scroll-top';
scrollTop.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as infoBox from './info-box';
infoBox.init();

import * as wishlist from './wishlist';
wishlist.init();
wishlist.initInScope();

import * as ping from './ping';
ping.init();

import * as callback from './callback';
callback.init();

import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as trackingExtend from './tracking'
trackingExtend.init();

import * as form from './form';
form.init();